import { css } from "@emotion/react";
import { PrimaryCTAHyperlink } from "@mytutor/shared-react-web-components/lib/PrimaryCTA";
import { breakpoints } from "@mytutor/shared-react-web-components/lib/theme";

const freeStudyResourcesStyle = css`
  max-width: 1034px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  padding: 80px 40px 40px;
  @media (max-width: ${breakpoints.largeDesktop}px) {
    max-width: 860px;
  }
  @media (max-width: ${breakpoints.tablet}px) {
    flex-direction: column;
    width: 80%;
  }
  @media (max-width: ${breakpoints.phone}px) {
    flex-direction: column;
    width: 100%;
  }

  h2 {
    font-family: "BureauGrotesk";
    font-size: 120px;
    line-height: 120px;
    margin: 0;
    @media (max-width: ${breakpoints.largeDesktop}px) {
      font-size: 80px;
      line-height: 80px;
    }
    @media (max-width: ${breakpoints.desktop}px) {
      font-size: 44px;
      line-height: 44px;
      max-width: 180px;
    }
  }
  p {
    margin: 0 0 20px;
  }
  div:nth-of-type(1) {
    flex: 2;
    background-image: url(/landing/book-pencil.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 90% center;
    max-width: 750px;
    @media (max-width: ${breakpoints.largeDesktop}px) {
      background-position: 75% center;
    }
    @media (max-width: ${breakpoints.desktop}px) {
      padding: 24px 0;
      background-position: 95% center;
    }
  }
  div:nth-of-type(2) {
    flex: 1;
    min-width: 284px;
    max-width: 284px;
    margin: auto 0;
    @media (max-width: ${breakpoints.tablet}px) {
      min-width: 0;
      max-width: 100%;
      padding: 24px 0 0;
    }
  }
`;

const FreeStudyResources = () => (
  <div css={freeStudyResourcesStyle}>
    <div>
      <h2>Free study resources</h2>
    </div>
    <div>
      <p>
        Over a million students use our free resources to help them with their
        homework.
      </p>
      <PrimaryCTAHyperlink href={process.env.NEXT_PUBLIC_QAA_URL}>
        Explore resources
      </PrimaryCTAHyperlink>
    </div>
  </div>
);

export default FreeStudyResources;
